import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";
import gsap from "gsap";
import {
  connectTwitter,
  checkAuthStatus,
  setupSacrificeButton,
} from "./controller.js";
export let mixer,
  step1,
  step2,
  step3,
  hover,
  login,
  yes,
  uiModel,
  gltfObject,
  youwill;
export function createUIScene(e) {
  const t = new THREE.Raycaster(),
    i = new THREE.Vector2(),
    o = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  window.addEventListener("mousemove", (e) => {
    if (o)
      return (
        console.log("it s mobil"),
        void (
          ((step1 && step1.visible && hover && hover.material) ||
            (step2 && step2.visible && hover && hover.material) ||
            (hover && hover.material)) &&
          (hover.material.opacity = 0.5)
        )
      );
    (i.x = (e.clientX / window.innerWidth) * 2 - 1),
      (i.y = (-e.clientY / window.innerHeight) * 2 + 1),
      t.setFromCamera(i, c);
    const a = t.intersectObjects(n.children, !0);
    step1 &&
      step1.visible &&
      (hover && hover.material && (hover.material.opacity = 0.5),
      a.forEach((e) => {
        e.object === login && (hover.material.opacity = 1);
      })),
      step2 &&
        step2.visible &&
        (hover && hover.material && (hover.material.opacity = 0.5),
        a.forEach((e) => {
          e.object === yes && (hover.material.opacity = 0.5);
        }));
  });
  const a = (e) => {
    let o, a;
    e.touches
      ? ((o = e.touches[0].clientX), (a = e.touches[0].clientY))
      : ((o = e.clientX), (a = e.clientY)),
      (i.x = (o / window.innerWidth) * 2 - 1),
      (i.y = (-a / window.innerHeight) * 2 + 1),
      t.setFromCamera(i, c);
    t.intersectObjects(n.children, !0).forEach((e) => {
      step1 &&
        step1.visible &&
        e.object === login &&
        (console.log("Login clicked!"), connectTwitter()),
        step2 &&
          step2.visible &&
          e.object === yes &&
          (console.log("yes clicked!"), setupSacrificeButton());
    });
  };
  window.addEventListener("mousedown", a),
    window.addEventListener("touchstart", a, { passive: !0 });
  const n = new THREE.Scene(),
    r = { width: window.innerWidth, height: window.innerHeight },
    l = r.width / r.height,
    s = 10;
  new RGBELoader().load(
    "./thatch_chapel_1k.hdr",
    (e) => {
      (e.mapping = THREE.EquirectangularReflectionMapping), (n.environment = e);
    },
    void 0,
    (e) => {
      console.error("Error loading environment map:", e);
    }
  );
  const c = new THREE.OrthographicCamera(
    (-10 * l) / 2,
    (s * l) / 2,
    5,
    -5,
    0.1,
    100
  );
  c.position.set(0, 0, 1), c.lookAt(0, 0, 0), n.add(c);
  const d = new THREE.WebGLRenderer({ canvas: e, antialias: !0, alpha: !0 });
  d.setSize(r.width, r.height),
    d.setPixelRatio(Math.min(window.devicePixelRatio, 2)),
    d.setClearColor(16777215, 0),
    (d.toneMapping = THREE.ReinhardToneMapping),
    (d.toneMappingExposure = 1);
  new GLTFLoader().load(
    "/models/scene (11).glb",
    (e) => {
      (gltfObject = e),
        (uiModel = e.scene),
        uiModel.scale.set(2.75, 2.75, 2.75),
        (uiModel.position.y -= 3),
        (step1 = uiModel.getObjectByName("textStep1")),
        (step2 = uiModel.getObjectByName("textStep2")),
        (step3 = uiModel.getObjectByName("textStep2001")),
        (hover = uiModel.getObjectByName("hover1")),
        (login = uiModel.getObjectByName("login")),
        (yes = uiModel.getObjectByName("yes")),
        (youwill = uiModel.getObjectByName("youWill001")),
        youwill &&
          ((youwill.material.transparent = !0), (youwill.material.opacity = 0)),
        checkAuthStatus(step1, step2),
        hover &&
          hover.isMesh &&
          hover.material &&
          ((hover.material.transparent = !0), (hover.material.opacity = 0.5)),
        o && (hover.material.opacity = 0.5),
        (step1.visible = !0),
        (step2.visible = !1),
        (step3.visible = !1),
        (uiModel.getObjectByName("RollR").material.depthWrite = !0),
        (uiModel.getObjectByName("occR").material.colorWrite = !1),
        (uiModel.getObjectByName("tell").material.color = new THREE.Color(
          986895
        )),
        (uiModel.getObjectByName("tell").material.color = new THREE.Color(
          986895
        )),
        (uiModel.getObjectByName("tell").material.color = new THREE.Color(
          986895
        )),
        (uiModel.getObjectByName("tell").material.color = new THREE.Color(
          986895
        )),
        (uiModel.getObjectByName("login").position.z = 0.19),
        (uiModel.getObjectByName("frame2").scale.z = 0.4),
        (uiModel.getObjectByName("hover2").scale.z = 0.4),
        (uiModel.getObjectByName("frame2").position.z = 0.28),
        (uiModel.getObjectByName("hover2").position.z = 0.28),
        (uiModel.getObjectByName("yes").position.z = 0.28);
      const t = uiModel.getObjectByName("frame");
      t &&
        t.isMesh &&
        t.material &&
        ((t.material.color = new THREE.Color(3092271)),
        (t.material.needsUpdate = !0)),
        (mixer = new THREE.AnimationMixer(uiModel));
      const i = THREE.AnimationClip.findByName(e.animations, "Animation");
      i &&
        setTimeout(() => {
          const e = mixer.clipAction(i);
          e.setLoop(THREE.LoopOnce), (e.clampWhenFinished = !0), e.play();
        }, 2e3),
        n.add(uiModel);
    },
    void 0,
    (e) => {
      console.error("Error loading UI model:", e);
    }
  );
  const m = new THREE.DirectionalLight(16777215, 1);
  m.position.set(5, 5, 5),
    n.add(m),
    window.addEventListener("resize", () => {
      (r.width = window.innerWidth), (r.height = window.innerHeight);
      const e = r.width / r.height;
      (c.left = (-10 * e) / 2),
        (c.right = (s * e) / 2),
        (c.top = 5),
        (c.bottom = -5),
        c.updateProjectionMatrix(),
        d.setSize(r.width, r.height),
        d.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    });
  const p = new THREE.Clock(),
    h = () => {
      const e = p.getDelta();
      mixer && mixer.update(e), t.setFromCamera(i, c);
      const a = t.intersectObjects(n.children, !0);
      o ||
        (step1 &&
          step1.visible &&
          (hover && hover.material && (hover.material.opacity = 0.5),
          step1 &&
            step1.visible &&
            a.forEach((e) => {
              e.object === login && (hover.material.opacity = 1);
            })),
        step2 &&
          step2.visible &&
          (hover && hover.material && (hover.material.opacity = 0.5),
          step2 &&
            step2.visible &&
            a.forEach((e) => {
              e.object === yes && (hover.material.opacity = 1);
            }))),
        d.render(n, c),
        requestAnimationFrame(h);
    };
  h();
}
let isListenerAdded = !1,
  animationPlayed = !1;
export function lastScene(e, t, i, o, a) {
  if (!e || !t || !i)
    return void console.error("Missing required parameters for lastScene.");
  const n = new THREE.AnimationMixer(e),
    r = THREE.AnimationClip.findByName(o.animations, "Animation");
  if (!r)
    return void console.error('Animation "Animation" not found in the model.');
  const l = n.clipAction(r);
  l.setLoop(THREE.LoopOnce),
    (l.clampWhenFinished = !0),
    (l.timeScale = -1),
    l.reset(),
    (l.time = r.duration),
    l.play(),
    isListenerAdded ||
      (n.addEventListener("finished", () => {
        console.log("Reverse animation finished"),
          (t.visible = !1),
          (i.visible = !0);
        const e = n.clipAction(r);
        (e.timeScale = 1),
          e.setLoop(THREE.LoopOnce),
          (e.clampWhenFinished = !0),
          e.reset(),
          e.play(),
          a &&
            a.material &&
            ((a.material.opacity = 0),
            (a.material.transparent = !0),
            gsap.to(a.material, {
              opacity: 1,
              delay: 1,
              duration: 3,
              onUpdate: () => {
                a.material.needsUpdate = !0;
              },
              onComplete: () => {
                console.log("Opacity animation completed");
              },
            })),
          n.addEventListener("finished", () => {
            animationPlayed = !0;
          }),
          console.log("Forward animation started");
      }),
      (isListenerAdded = !0));
  const s = new THREE.Clock();
  !(function e() {
    if (!animationPlayed) {
      const t = s.getDelta();
      n.update(t), requestAnimationFrame(e);
    }
  })();
}
