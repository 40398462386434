import { animateEmissiveColor } from "./script.js";
import {
  uiModel,
  step2,
  step3,
  gltfObject,
  lastScene,
  youwill,
} from "./uiScene.js";
let isLastSceneTriggered = !1;
const baseUrl = "https://www.thedeath.xyz/";
var bless = new Audio("/sfx.mp3");
(bless.loop = !0),
  document.addEventListener("click", () => {
    bless.paused && bless.play();
  });
export async function connectTwitter() {
  bless.paused && bless.play();
  try {
    const e = await fetch(`${baseUrl}/api/auth`),
      t = await e.json();
    window.location.href = t.url;
  } catch (e) {
    console.error("Error initiating authentication:", e);
  }
}
export async function checkAuthStatus(e, t) {
  try {
    const n = await fetch(`${baseUrl}/api/auth-status`, {
      method: "GET",
      credentials: "include",
    });
    (await n.json()).authenticated
      ? (e && (e.visible = !1),
        t && (t.visible = !0),
        setTimeout(() => {
          document.getElementById("tweet-step").style.visibility = "visible";
        }, 5e3),
        bless.paused && bless.play(),
        (window.isAuthenticated = !0))
      : ((window.isAuthenticated = !1),
        e && (e.visible = !0),
        t && (t.visible = !1),
        (document.getElementById("tweet-step").style.visibility = "hidden"));
  } catch (e) {
    console.error("Error checking authentication status:", e);
  }
}
export function setupSacrificeButton() {
  bless.paused && bless.play();
  const e = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    "When will my end come? @DEATHdotfun #DEATHdotfun"
  )}`;
  if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent))
    window.location.href = e;
  else {
    const t = 600,
      n = 400,
      o = (screen.width - t) / 2,
      s = (screen.height - n) / 2;
    window.open(
      e,
      "TweetWindow",
      `width=${t},height=${n},top=${s},left=${o},toolbar=no,menubar=no,scrollbars=no,resizable=no,status=no`
    );
  }
}
function getCookie(e) {
  const t = `; ${document.cookie}`.split(`; ${e}=`);
  return 2 === t.length ? t.pop().split(";").shift() : null;
}
document.addEventListener("DOMContentLoaded", function () {
  const e = document.getElementById("tweetCheck"),
    t = document.getElementById("check-button");
  e.addEventListener("submit", async function (e) {
    if ((e.preventDefault(), t.disabled))
      return void console.log(
        "Please wait for the cooldown to finish.",
        "white"
      );
    const n = (function () {
      const e = document.getElementById("tweet_url_1").value.trim();
      if (!e) return console.log("Please enter a Tweet URL.", "white"), null;
      let t = e;
      t.startsWith("http://") ||
        t.startsWith("https://") ||
        (t = "https://" + t);
      return t.match(
        /^https?:\/\/x\.com\/([A-Za-z0-9_]+)\/status\/(\d+)(?:\?.*)?$/
      )
        ? t
        : (console.log("Invalid Tweet URL format.", "white"), null);
    })();
    if (!n) return;
    const o = (function (e) {
        const t = e.match(
          /^https?:\/\/x\.com\/([A-Za-z0-9_]+)\/status\/(\d+)(?:\?.*)?$/
        );
        return t ? t[1] : null;
      })(n),
      s = getCookie("username");
    if (s)
      if (o === s) {
        try {
          (
            await fetch("/api/save-tweet-url", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ tweet_url: n }),
            })
          ).ok && console.error("ok");
        } catch (e) {
          console.error("Error saving Tweet URL:", e);
        }
        console.log("PASS TO ANOTHER STEP"),
          (document.getElementById("tweet-step").style.visibility = "hidden"),
          uiModel
            ? lastScene(uiModel, step2, step3, gltfObject, youwill)
            : console.error("uiModel is not yet loaded."),
          animateEmissiveColor("#FF0000"),
          bless.paused && bless.play(),
          document.getElementById("tweet-step").classList.add("none");
      } else
        console.log(
          "Username mismatch. You are not the owner of this tweet.",
          "red"
        );
    else console.log("No username found in cookies. Please log in.", "red");
  });
}),
  document.addEventListener("DOMContentLoaded", function () {
    document
      .getElementById("ref-twitter")
      .addEventListener("click", function () {
        window.open("https://x.com/DEATHdotfun", "_blank");
      });
  });
