import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";
import { GUI } from "lil-gui";
import gsap from "gsap";
import { createUIScene } from "./uiScene.js";
const canvas2 = document.querySelector("#uiCanvas");
createUIScene(canvas2);
const loadingBarElement = document.querySelector(".loading");
let mixer,
  composer,
  sceneReady = !1;
const loadingManager = new THREE.LoadingManager(() => {
    setTimeout(() => {
      loadingBarElement.classList.add("hidden"),
        setTimeout(() => {
          loadingBarElement.style.display = "none";
        }, 500);
    }, 1e3);
  }),
  gltfLoader = new GLTFLoader(loadingManager),
  rgbeLoader = new RGBELoader(loadingManager),
  canvas = document.querySelector("canvas.webgl"),
  scene = new THREE.Scene();
rgbeLoader.load(
  "./environmentMaps/0/2k.hdr",
  (e) => {
    (e.mapping = THREE.EquirectangularReflectionMapping),
      (scene.environment = e);
  },
  void 0,
  (e) => {
    console.error("Error loading environment map:", e);
  }
);
const sizes = { width: window.innerWidth, height: window.innerHeight },
  camera = new THREE.PerspectiveCamera(
    75,
    sizes.width / sizes.height,
    0.1,
    100
  );
camera.position.set(0, 0, 5), scene.add(camera);
const controls = new OrbitControls(camera, canvas);
(controls.enableDamping = !0),
  (controls.enablePan = !1),
  (controls.enableZoom = !1);
const renderer = new THREE.WebGLRenderer({ canvas: canvas, antialias: !0 });
renderer.setSize(sizes.width, sizes.height),
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2)),
  (renderer.toneMapping = THREE.ReinhardToneMapping),
  (renderer.toneMappingExposure = 0.1),
  (renderer.shadowMap.enabled = !0),
  (composer = new EffectComposer(renderer));
const renderPass = new RenderPass(scene, camera);
composer.addPass(renderPass);
const bloomParams = { threshold: 0, strength: 0.75, radius: 0 },
  bloomPass = new UnrealBloomPass(
    new THREE.Vector2(sizes.width, sizes.height),
    bloomParams.strength,
    bloomParams.radius,
    bloomParams.threshold
  );
let zBgObject;
composer.addPass(bloomPass),
  gltfLoader.load("/models/angel.glb", (e) => {
    e.scene.scale.set(2, 2, 2),
      (e.scene.position.y -= 2),
      scene.add(e.scene),
      (mixer = new THREE.AnimationMixer(e.scene));
    const s = THREE.AnimationClip.findByName(e.animations, "Action");
    if (s) {
      const e = mixer.clipAction(s);
      e.setLoop(THREE.LoopRepeat), e.play();
    }
    (zBgObject = e.scene.getObjectByName("z_bg")),
      zBgObject &&
        zBgObject.isMesh &&
        ((zBgObject.material.emissive = new THREE.Color(16777215)),
        (zBgObject.material.emissiveIntensity = 3));
  });
export function animateEmissiveColor(e) {
  if (zBgObject && zBgObject.material) {
    const s = new THREE.Color(zBgObject.material.emissive.getHex()),
      o = new THREE.Color(e),
      r = { r: s.r, g: s.g, b: s.b };
    gsap.to(r, {
      r: o.r,
      g: o.g,
      b: o.b,
      delay: 2,
      duration: 4,
      onUpdate: () => {
        zBgObject.material.emissive.setRGB(r.r, r.g, r.b);
      },
    });
  }
}
window.addEventListener("resize", () => {
  (sizes.width = window.innerWidth),
    (sizes.height = window.innerHeight),
    (camera.aspect = sizes.width / sizes.height),
    camera.updateProjectionMatrix(),
    renderer.setSize(sizes.width, sizes.height),
    composer.setSize(sizes.width, sizes.height),
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});
const clock = new THREE.Clock(),
  tick = () => {
    const e = clock.getDelta();
    controls.update(),
      mixer && mixer.update(0.75 * e),
      composer.render(),
      window.requestAnimationFrame(tick);
  };
tick();
